<template>
  <div id="app">
    <a-layout class="layout">
      <a-layout-header class="header">
        <div class="logo" />
        <a-menu
          theme="dark"
          mode="horizontal"
          :selectedKeys="selectedKeys"
          :style="{ lineHeight: '64px' }"
        >
          <a-menu-item key="offline">
            <router-link to="/">
              Offline Version
            </router-link>
          </a-menu-item>
          <a-menu-item key="online">
            <router-link to="/online">
              Online Version
            </router-link>
          </a-menu-item>
        </a-menu>
      </a-layout-header>

      <a-layout-content class="content">
        <router-view></router-view>
      </a-layout-content>

      <a-layout-footer class="footer" style="text-align: center">
        IR Convertor © 2021 Created by Sonicake
      </a-layout-footer>
    </a-layout>
  </div>
</template>

<script>
export default {
  computed: {
    selectedKeys() {
      return [this.$route.name]
    },
  },
}
</script>

<style scoped>
.layout {
  min-height: 100vh;
}

.content {
  background: #fff;
  padding: 24px;
  min-height: 280px;
}

.layout .logo {
  width: 120px;
  height: 31px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px 24px 16px 0;
  float: left;
}
</style>
