<template>
  <div class="offline-wrapper">
    <div class="upload-container">
      <h2 class="title">IR CONVERTER</h2>
      <a-upload-dragger
        class="upload-dragger"
        name="file"
        accept="audio/wav"
        :multiple="true"
        :before-upload="beforeUpload"
        :remove="handleRemove"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
          Click or drag your IR file here!
        </p>
      </a-upload-dragger>
      <a-button
        type="primary"
        class="convert-btn"
        :disabled="disabled"
        :loading="btnLoading"
        @click="handleClick"
      >
        Convert & Download
      </a-button>
      <p class="desc-text">
        * IR CONVERTER can convert any wav file into a 24-bit, 44.1kHz mono wav
        file and cut it to a suitable length. But please note that the size of
        the uploaded file cannot exceed 1MB.
      </p>
      <p>
        We recommend that you use the
        <router-link to="/">offline version</router-link> (faster and more
        stable). If your browser cannot load the offline version, please use the
        online version to convert.
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { saveAs } from 'file-saver'
import config from '@/config/config'

const FILE_LIMIT = 1

export default {
  name: 'Online',

  watch: {
    fileList(val, _) {
      if (val.length > 0 && val.length <= FILE_LIMIT) {
        this.disabled = false
      } else {
        this.disabled = true
      }

      if (val.length > FILE_LIMIT) {
        this.$message.error(`Convert ${FILE_LIMIT} IR file at a time!`)
      }
    },
  },

  data() {
    return {
      file_limit: FILE_LIMIT,
      btnLoading: false,
      disabled: true,
      fileList: [],
      downloadList: [],
    }
  },

  methods: {
    beforeUpload(file) {
      const isWav =
        file.type === 'audio/wav' ||
        file.type === 'audio/wave' ||
        file.type === 'audio/x-wav'
      if (!isWav) this.$message.error('You can only upload WAV file!')

      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isLt1M) this.$message.error('WAV file must smaller than 1MB!')

      if (isWav && isLt1M) {
        this.fileList = [...this.fileList, file]
        return false
      } else {
        return true
      }
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((val) => {
        return val.uid != file.uid
      })
    },

    async handleClick() {
      // trigger btn loading
      this.btnLoading = true

      // filelist to formdata
      const formdata = new FormData()
      for (const file of this.fileList) {
        formdata.append('file', file)
      }

      // post to the convert api
      const uploadURL = `${config.baseURL}/ir_convert`
      try {
        const res = await axios.post(uploadURL, formdata, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        if (res.data.code === 200) {
          this.downloadList = res.data.data
        } else {
          this.$message.error('An error occurred during the conversion!')
          console.log(res.data.err)
        }
      } catch (err) {
        this.$message.error('An error occurred during the conversion!')
        console.log(err)
      } finally {
        this.btnLoading = false
      }

      // download
      for (const file of this.downloadList) {
        saveAs(file.url, file.filename)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-container {
  max-width: 960px;
  margin: 0 auto;

  .title {
    text-align: center;
    margin-bottom: 20px;
  }

  .upload-dragger {
    margin-bottom: 20px;
  }

  .convert-btn {
    display: block;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
