import Vue from 'vue'
import VueRouter from 'vue-router'
import Offline from '../views/Offline.vue'
import Online from '../views/Online.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'offline',
    component: Offline,
  },
  {
    path: '/online',
    name: 'online',
    // component: () =>
    // import(/* webpackChunkName: "Online" */ '../views/Online.vue'),
    component: Online,
  },
]

const router = new VueRouter({
  routes,
})

export default router
