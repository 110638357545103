<template>
  <div class="offline-wrapper">
    <a-spin
      tip="Downloading dependent scripts, it will take about a few minutes."
      v-if="loading"
      size="large"
    >
      <div class="spin-content"></div>
    </a-spin>

    <div class="upload-container" v-if="!loading">
      <h2 class="title">IR CONVERTER</h2>
      <a-upload-dragger
        class="upload-dragger"
        name="file"
        accept="audio/wav"
        :multiple="true"
        :before-upload="beforeUpload"
        :remove="handleRemove"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
          Click or drag your IR file here!
        </p>
      </a-upload-dragger>
      <a-button
        type="primary"
        class="convert-btn"
        :disabled="disabled"
        :loading="btnLoading"
        @click="handleClick"
      >
        Convert & Download
      </a-button>
    </div>
    <p class="desc-text">
      * IR CONVERTER can convert any wav file into a 24-bit, 44.1kHz mono wav
      file and cut it to a suitable length. But please note that the size of the
      uploaded file cannot exceed 1MB.
    </p>
    <p>
      We recommend that you use the offline version (faster and more stable). If
      your browser cannot load the offline version, please use the
      <router-link to="online">online version</router-link> to convert.
    </p>
  </div>
</template>

<script>
const { createFFmpeg, fetchFile } = FFmpeg
const ffmpeg = createFFmpeg({
  corePath: '/ffmpeg-core.js',
  log: true,
})

const FILE_LIMIT = 1
const key = 'updatable'

export default {
  name: 'Offline',

  async created() {
    try {
      if (!ffmpeg.isLoaded()) await ffmpeg.load()
      this.loading = false
    } catch (error) {
      this.$notification['error']({
        key,
        message: 'Browser not support',
        description:
          'Your browser not support this offline version, please try online version.',
        duration: null,
        btn: (h) => {
          return h(
            'a-button',
            {
              props: {
                type: 'primary',
                size: 'small',
              },
              on: {
                click: () => {
                  this.$notification.close(key)
                  this.$router.push('/online')
                },
              },
            },
            'Go online version'
          )
        },
      })
    }
  },

  watch: {
    fileList(val, _) {
      if (val.length > 0 && val.length <= FILE_LIMIT) {
        this.disabled = false
      } else {
        this.disabled = true
      }

      if (val.length > FILE_LIMIT) {
        this.$message.error(`Convert ${FILE_LIMIT} IR file at a time!`)
      }

      this.bufferList = []
      val.forEach((val) => {
        const file = val
        const filename = val.name
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)
        reader.onload = async () => {
          // const buffer = new Uint8Array(reader.result)
          const buffer = await fetchFile(reader.result)
          this.bufferList.push({ buffer, filename })
        }
      })
    },
  },

  data() {
    return {
      file_limit: FILE_LIMIT,
      loading: true,
      btnLoading: false,
      disabled: true,
      fileList: [],
      bufferList: [],
    }
  },

  methods: {
    beforeUpload(file) {
      const isWav =
        file.type === 'audio/wav' ||
        file.type === 'audio/wave' ||
        file.type === 'audio/x-wav'
      if (!isWav) this.$message.error('You can only upload WAV file!')

      const isLt1M = file.size / 1024 / 1024 < 1
      if (!isLt1M) this.$message.error('WAV file must smaller than 1MB!')

      if (isWav && isLt1M) {
        this.fileList = [...this.fileList, file]
        return false
      } else {
        return true
      }
    },

    handleRemove(file) {
      this.fileList = this.fileList.filter((val) => {
        return val.uid != file.uid
      })
    },

    async handleClick() {
      // trigger btn loading
      this.btnLoading = true

      try {
        // use `for of` statement to convert file in order
        for (const val of this.bufferList) {
          const { buffer, filename } = val
          ffmpeg.FS('writeFile', 'input.wav', buffer)
          await ffmpeg.run(
            '-i',
            'input.wav',
            '-acodec',
            'pcm_s24le',
            '-ac',
            '1',
            '-ar',
            '44100',
            '-bitexact',
            '-t',
            '0.023219955',
            'output.wav'
          )
          const file = ffmpeg.FS('readFile', 'output.wav')
          ffmpeg.FS('unlink', 'input.wav')
          ffmpeg.FS('unlink', 'output.wav')

          // ! change type of format (at index 20,21) to 01 00 (PCM)
          // ! ffmpeg codec pcm_s24le format default is FE FF
          file[20] = 1
          file[21] = 0

          // download
          const blob = new File([file], filename, {
            type: 'audio/wav',
          })
          const link = document.createElement('a')
          link.download = filename
          link.href = URL.createObjectURL(blob)
          link.click()
        }
      } catch (err) {
        this.$message.error('An error occurred during the conversion!')
        console.log(err)
      } finally {
        this.btnLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.spin-content {
  max-width: 960px;
  height: 264px;
  padding: 30px;
  margin-bottom: 10px;
  text-align: center;
  border: 1px solid #91d5ff;
  background-color: #e6f7ff;
}

.offline-wrapper {
  max-width: 960px;
  margin: 0 auto;
  .upload-container {
    .title {
      text-align: center;
      margin-bottom: 20px;
    }

    .upload-dragger {
      margin-bottom: 20px;
    }

    .convert-btn {
      display: block;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
